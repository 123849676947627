export interface Fence { id: string, points: number[][] }

// 4.0075e7 is Earth's circumference in meters.
const DEGREES_PER_METER = 360 / 4.0075e7;
// 10m buffer
const BUFFER_METERS = 10;
const BUFFER_DEGREES = BUFFER_METERS * DEGREES_PER_METER;

export function expandBox(box: number[], buffer = BUFFER_DEGREES) {
    box[0] = box[0] - buffer / latitudeScale(box[1]);
    box[1] = box[1] - buffer;
    box[2] = box[2] + buffer / latitudeScale(box[3]);
    box[3] = box[3] + buffer;
}

export type Box = number[];

export const boxUtility = {
    contains: (box: number[], inside: number[]) => {
        return inside[0] >= box[0] && inside[1] >= box[1] && inside[2] <= box[2] && inside[3] <= box[3];
    },
    intersect: (box: number[], point: number[]) => {
        return point[0] >= box[0] && point[0] <= box[2] && point[1] >= box[1] && point[1] <= box[3];
    },
    union: (box: number[], inside: number[]) => {
        return [Math.min(box[0], inside[0]), Math.min(box[1], inside[1]), Math.max(box[2], inside[2]), Math.max(box[3], inside[3])];
    },
    area: (box: number[]) => {
        return (box[2] - box[0]) * (box[3] - box[1]);
    },
    fromPoints: (points: number[][], expand = BUFFER_METERS): number[] => {
        const box: number[] = [
            points[0][0],
            points[0][1],
            points[0][0],
            points[0][1],
        ];
        for (let i = 1; i < points.length; i++) {
            box[0] = Math.min(box[0], points[i][0]);
            box[1] = Math.min(box[1], points[i][1]);
            box[2] = Math.max(box[2], points[i][0]);
            box[3] = Math.max(box[3], points[i][1]);
        }
        expandBox(box, expand * DEGREES_PER_METER);
        return box;
    },
    inside: (points: number[][], point: number[], strict: boolean) => insidePolygon(points, point, strict),
};

export const circleUtility = {
    contains: (box: number[], inside: number[]) => {
        const dist = pointDistance(box, inside);
        return dist + inside[2] <= box[2];
    },
    intersect: (box: number[], point: number[]) => {
        const dist = pointDistance(box, point);
        return dist <= box[2];
    },
    union: (box: number[], inside: number[]) => {
        const distance = pointDistance(box, inside);
        const w1 = Math.max(0, distance + box[2] - inside[2]);
        const w2 = Math.max(0, distance + inside[2] - box[2]);
        const loc = [box[0] * w1 / (w1 + w2) + inside[0] * w2 / (w1 + w2), box[1] * w1 / (w1 + w2) + inside[1] * w2 / (w1 + w2), 0];
        loc[2] = Math.max(box[2] + pointDistance(loc, box), inside[2] + pointDistance(loc, inside));
        return loc;
    },
    area: (box: number[]) => {
        return Math.PI * box[2] * box[2];
    },
    fromPoints: (points: number[]): number[] => {
        return points;
    },
    inside: (points: number[], point: number[], strict: boolean) => {
        const dist = pointDistance(points, point);
        return strict ? dist <= points[2] : dist <= points[2] + BUFFER_DEGREES;
    },
};

export function intersectsPolygon(points: number[][], bounds: number[][]) {
    const box: number[] = [bounds[0][1], bounds[0][0], bounds[1][1], bounds[1][0]];
    return points.some(p => boxUtility.intersect(box, p))
        || insidePolygon(points, [0.5 * (bounds[0][0] + bounds[1][0]), 0.5 * (bounds[0][1] + bounds[1][1])])
        || points.some((p, i) => intersectsLine(bounds, p, points[(i + 1) % points.length]));
}

// strict = true has no buffer for being inside the polygon when on the edge (default is 10m)
export function insidePolygon(points: number[][], point: number[], strict?: boolean): boolean;
// distance can customize the buffer for being inside the polygon when on the edge
export function insidePolygon(points: number[][], point: number[], distance?: number): boolean;
export function insidePolygon(points: number[][], point: number[], strict: boolean | number = false) {
    const r = typeof strict === 'number' ? strict : BUFFER_METERS;
    const distance = polygonDistance(points, point, strict);
    return strict === true ? distance <= 0 : distance <= r;
}

export function polygonDistance(points: number[][], point: number[], strict: boolean | number = false) {
    let cross = false;
    const x = point[0];
    const y = point[1];
    const ly = latitudeScale(y);
    let minDistance = Infinity;
    for (let i = 0; i < points.length; i++) {
        const x1 = points[i][0];
        const y1 = points[i][1];
        const x2 = points[i === points.length - 1 ? 0 : i + 1][0];
        const y2 = points[i === points.length - 1 ? 0 : i + 1][1];

        if (strict !== true) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const pointDistance = (x - x1) * (x - x1) * ly * ly + (y - y1) * (y - y1);
            if (pointDistance < minDistance) minDistance = pointDistance;

            const d = (y2 - y1) * y + (x2 - x1) * x;
            const d1 = (y2 - y1) * y1 + (x2 - x1) * x1;
            const d2 = (y2 - y1) * y2 + (x2 - x1) * x2;
            if ((d > d1 || d > d2) && (d < d1 || d < d2)) {
                const ni = 1.0 / ((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));
                const ry = y1 + (y2 - y1) * (d - d1) * ni;
                const rx = x1 + (x2 - x1) * (d - d1) * ni;
                const lineDistance = (x - rx) * (x - rx) * ly * ly + (y - ry) * (y - ry);
                if (lineDistance < minDistance) minDistance = lineDistance;
            }
        }

        if (y1 === y2) continue;

        const t = (y - y1) / (y2 - y1);
        if (t < 0 || t >= 1) continue;

        const nx = (x2 - x1) * t + x1;
        if (nx >= x) cross = !cross;
    }
    return cross ? 0 : Math.sqrt(minDistance) / DEGREES_PER_METER;
}

const CHILD_COUNT = 4;
interface BoxTreeEntry<T> {
    children: Array<BoxTreeEntry<T>>;
    object: T | null;
    box: number[];
}

/**
 * Scaling factor that is 1 at the equator and almost 0 at the poles.
 */
export function latitudeScale(y: number) {
    return Math.cos(Math.min(89, Math.abs(y)) * Math.PI / 180);
}

function intersectsLine(box: number[][], p1: number[], p2: number[]) {
    for (let i = 0; i < 2; i++) {
        for (let j = 0; j < 2; j++) {
            if ((p1[i] < box[j][i]) !== (p2[i] < box[j][i])) {
                const i1 = p1[1 - i] + (p2[1 - i] - p1[1 - i]) * (box[j][i] - p1[i]) / (p2[i] - p1[i]);
                if (i1 >= box[0][1 - i] && i1 <= box[1][1 - i]) return true;
            }
        }
    }
    return false;
}

export function pointDistance(p1: number[], p2: number[]) {
    const scale = latitudeScale(0.5 * (p1[1] + p2[1]));
    const p1x = p1[0];
    const p1y = p1[1];
    const p2x = p2[0];
    const p2y = p2[1];
    return Math.sqrt(
        (p1x - p2x) * (p1x - p2x) * scale * scale + (p1y - p2y) * (p1y - p2y),
    ) * 4.0075e7 / 360;
}

// Calculate the heading from p1 to p2
// 0 degrees is true north, going clockwise
export function findHeading(p1: number[], p2: number[]) {
    const scale = latitudeScale(0.5 * (p1[1] + p2[1]));
    const p1x = p1[0];
    const p1y = p1[1];
    const p2x = p2[0];
    const p2y = p2[1];

    return Math.atan2((p2x - p1x) * scale, p2y - p1y) * 180 / Math.PI;
}

const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];
export function translateHeading(heading: number) {
    if (heading < 0) heading += 360;
    const index = Math.round(heading / 22.5);
    return directions[index];
}

export class BoxTree<T extends { id?: unknown }> {
    root?: BoxTreeEntry<T>;

    constructor(
        private readonly points: (obj: T) => number[][],
        private readonly level?: ((obj: T) => number) | null,
        // removing any would require significant refactoring
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private readonly utility: any = boxUtility,
    ) {}

    remove(object: T) {
        if (this.root) {
            const box = this.utility.fromPoints(this.points(object));
            return this.removeBox(object, box, this.root);
        }
        return false;
    }

    private removeBox(object: T, box: number[], current: BoxTreeEntry<T>) {
        if (current.object === object || current.object && current.object['id'] === object['id']) {
            current.object = null;
            return true;
        }
        if (!this.utility.contains(current.box, box)) return false;
        for (const child of current.children) {
            if (this.removeBox(object, box, child)) {
                return true;
            }
        }
        return false;
    }

    add(object: T) {
        const points = this.points(object);

        const box = this.utility.fromPoints(points);

        let current = this.root;
        while (current) {
            if (!this.utility.contains(current.box, box)) {
                if (current.object) {
                    current.children.push({ children: [], object: current.object, box: current.box });
                    current.object = null;
                }
                current.box = this.utility.union(current.box, box);
            }
            if (current.children.length < CHILD_COUNT) {
                current.children.push({ children: [], object, box });
                return;
            }

            let best = -1;
            let bestArea = 0;
            for (let i = 0; i < current.children.length; i++) {
                if (this.utility.contains(current.children[i].box, box)) {
                    best = i;
                    bestArea = 0;
                    break;
                } else {
                    const newArea = this.utility.area(this.utility.union(current.children[i].box, box)) - this.utility.area(current.children[i].box);
                    if (best === -1 || newArea < bestArea) {
                        best = i;
                        bestArea = newArea;
                    }
                }
            }
            current = current.children[best];
        }
        this.root = {
            children: [],
            object,
            box,
        };
    }

    /**
     * Populates `inside` with polygons for which the given 3D point is strictly inside.
     * Populates `outside` with polygons for which the given 3D point is either strictly inside OR close enough to inside, for a small buffer radius.
     */
    find(after: number[], inside: T[], outside?: T[]) {
        if (this.root) {
            this.findBox(after, inside, outside, this.root);
        }
    }

    private findBox(after: number[], inside: T[], outside: T[] | undefined, box: BoxTreeEntry<T>) {
        if (!this.utility.intersect(box.box, after)) return;

        if (box.object) {
            const level = this.level?.(box.object);
            if (level == null || level === (after[2] ?? 1)) {
                const points = this.points(box.object);
                if (this.utility.inside(points, after, true)) inside.push(box.object);
                if (outside && this.utility.inside(points, after)) outside.push(box.object);
            }
        }
        for (const child of box.children) {
            this.findBox(after, inside, outside, child);
        }
    }
}

export class CircleTree<T extends { id?: unknown }> extends BoxTree<T> {
    constructor(points: (obj: T) => number[]) {
        super(points as never, null, circleUtility);
    }
}

// Graham scan algorithm to find the polygonal convex hull
export function convexHull(points: number[][]) {
    if (!points.length) return [];

    // pivot point is bottom-most, then left-most point
    let pivot = points[0];
    for (let i = 1; i < points.length; i++) {
        if (points[i][1] < pivot[1] || points[i][1] === pivot[1] && points[i][0] < pivot[0]) pivot = points[i];
    }
    // calculate angles and distances
    const angles = points.map(x => Math.atan2(x[1] - pivot[1], x[0] - pivot[0]));
    const distances = points.map(x => (x[0] - pivot[0]) * (x[0] - pivot[0]) + (x[1] - pivot[1]) * (x[1] - pivot[1]));
    // Sort by angle then by distance
    const indexes = points.map((_, i) => i).sort((i, j) => {
        return angles[i] === angles[j] ? distances[i] - distances[j] : angles[i] - angles[j];
    });
    const hull: number[][] = [pivot];
    for (let i = 1; i < indexes.length; i++) {
        // Only use farthest point for matching angles
        if (angles[indexes[i]] === angles[indexes[i + 1]]) continue;
        const point = points[indexes[i]];
        while (hull.length >= 3) {
            const penultimate = hull[hull.length - 2];
            const ultimate = hull[hull.length - 1];
            // slope of previous segment must be less than slop of next segment or else is inside and should be removed
            const ccw = (ultimate[1] - penultimate[1]) * (point[0] - ultimate[0]) - (point[1] - ultimate[1]) * (ultimate[0] - penultimate[0]);
            if (ccw < 0) break;
            hull.pop();
        }
        hull.push(point);
    }
    return hull;
}

// legacy and WRONG, do not use
export function fromMeters(location: number[]) {
    const latitude = location[1] * 360 / 4.0075e7;
    return [
        location[0] * 360 / 4.0075e7 / Math.cos(latitude * Math.PI / 180),
        latitude,
    ];
}

// legacy and WRONG, do not use
export function toMeters(location: number[]) {
    return [
        location[0] * 4.0075e7 / 360 * Math.cos(location[1] * Math.PI / 180),
        location[1] * 4.0075e7 / 360,
    ];
}
